import { createStore } from 'vuex';
import { loadingController, alertController } from '@ionic/vue';
import { Storage } from '@ionic/storage';

const storage = new Storage();
storage.create();

const store = createStore({
  state() {
    return {
      windgrams: [],
      map: null,
      markers: [],
      selectedDay: 0,
      selectedRegion: -1,
      selectedWindgram: {
        windgram_id: 0,
        coord: {
          lat: 0,
          lon: 0
        }
      },

      key: null,

      doneDisclamer: false,
      preferred: [],
      initialised: false,
      allWindgrams: [],
      allMarkers: [],
      version: {
        version: '',
        description: ''
      }
    };
  },
  getters: {
    map(state) {
      return state.map;
    },
    markers(state) {
      return state.markers;
    },
    preferred(state) {
      return state.preferred;
    },
    isPreferred(state) {
      const found = state.preferred.filter( el => el.windgram_id == state.selectedWindgram.windgram_id ); 
      if (found.length == 0) {
        return false;
      } else {
        return true;
      }
    },
    day(state) {
      return state.selectedDay;
    },
    region_id(state) {
      return state.selectedRegion;
    },
    windgram(state) {
      return state.selectedWindgram;
    },
    windgrams(state) {
      return state.windgrams;
    },
    region_list(state) {
        if (state.selectedRegion != -1) {
          return state.windgrams.find((region_item) => region_item.region_id === state.selectedRegion);
        } else {
          return null;
        }
    },
    key(state) {
      //while (!state.initialised);
      return state.key
    },
    disclamer(state) {
      return state.doneDisclamer
    },
    allWindgrams(state) {
      return state.allWindgrams
    },
    version(state) {
      return state.version
    }
  },
  mutations: {
    saveMap(state,payload) {
      state.map = payload;
    },
    deleteMap(state) {
      delete state.map;
    },
    saveMarkers(state,payload) {
      state.markers = payload;
    },
    saveAllWindgrams(state,payload) {
      payload.forEach(element => {
        element.windgram_list.forEach(wg => {
          state.allWindgrams.push(wg)
        });
      });
    },
    initialised(state) {
      state.initialised = true;
    },
    setPreferred(state, payload) {
      state.preferred = payload;
    },
    async addPreferred(state) {
      state.preferred = state.preferred.concat(state.selectedWindgram);
      state.windgrams[0].windgram_list = JSON.parse(JSON.stringify(state.preferred));
      const json = JSON.stringify(state.preferred);
      await storage.set('preferred',json);
    },
    async removePreferred(state) {
      for (let index = 0; index < state.preferred.length; index++) {
        if (state.preferred[index].windgram_id == state.selectedWindgram.windgram_id) {
          state.preferred.splice(index,1);
          if (state.preferred.length == 0) {
            state.windgrams[0].windgram_list = [];
          } else {
            state.windgrams[0].windgram_list = JSON.parse(JSON.stringify(state.preferred));
          }
          const json = JSON.stringify(state.preferred);
          await storage.set('preferred',json);
          break;
        }
      }
    },
    saveWindgrams(state, payload){
        state.windgrams = payload;
    },
    saveDay(state,payload) {
        state.selectedDay = payload
    },
    saveRegion(state,payload) {
      state.selectedRegion = payload
    },
    saveWindgram(state,payload) {
      state.selectedWindgram = payload
    },
    saveKey(state,payload) {
      state.key = payload;
      storage.set('key',payload);
      //localStorage.setItem("key",payload)
      //await Storage.set({
      //  key: 'key',
      //  value: payload,
      //});
    },
    saveVersion(state,payload) {
      state.version = payload;
      storage.set('version',payload);
    },
    deleteKey() {
      storage.remove('key');
    },
    doneDisclamer(state) {
      state.doneDisclamer = true;
    },
    setKey(state,payload) {
      state.key = payload;
    },
    async loadKey(state) {
      state.key = await storage.get('key');
      //console.log('store: '+state.key);
      state.initialised = true;
    },   
    async loadVersion(state) {
      state.version = await storage.get('version');
    }, 
  },
  actions: {
    async loadPreferred(context) {
      //await storage.remove('preferred');
      let a = await storage.get('preferred');
      if ( a !== null ) {
        context.commit('setPreferred', JSON.parse(a))
      }
    },
    //async loadKey(context) {
    //  const theKey = await storage.get('key');
    //  context.commit('setKey',theKey);
    //  context.commit('initialised');
    //},
    async fetchWindgrams(context){
      const loading = await loadingController
      .create({
        //cssClass: 'my-custom-class',
        message: 'Please wait...',
        //duration: this.timeout,
      });
      await loading.present();
      try {
        const res = await fetch('https://www.cumulus.it/php/ajax.new.php?type=v-windgrams')
        const data = await res.json();
        context.commit('saveAllWindgrams',data);

        let wl = [];
        if (context.getters.preferred.length != 0 )  wl = JSON.parse(JSON.stringify(context.getters.preferred));
        const preferred = [{
          region_id: '0',
          region_name: "Preferiti",
          windgram_list: wl
        }];
        context.commit('saveWindgrams', preferred.concat(data));
        loading.dismiss()
      } catch (e) {
        loading.dismiss();
        const myAlert = await alertController
        .create({
          header: "Attenzione",
          subHeader: "",
          message: "Errore di rete. No Internet?",
          buttons: ["Riprova"],
        });
        await myAlert.present();
        await myAlert.onDidDismiss();
        this.dispatch('fetchWindgrams')
      }
    },
  },
});

export default store;