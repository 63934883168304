<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button />
        </ion-buttons>
        <ion-title v-if="loadedList != null">{{loadedList.region_name}}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div v-if="loadedList != null && loadedList.windgram_list.length == 0" class="ion-padding">
      <ion-text >Per aggiungere o rimuovere un windgram dai preferiti usa l'icona a forma di cuore nella pagina del windgram.</ion-text>
      </div>
      <ion-list v-if="loadedList != null">
        <ion-item 
          v-for="item in loadedList.windgram_list"
          :key="item.windgram_id" 
          @click="selectWindgram(item)"
        >
          {{item.windgram_name}}
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonText, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonButtons, IonBackButton, } from '@ionic/vue';

export default {
    components: {
      IonContent,
      IonHeader,
      IonPage,
      IonTitle,
      IonToolbar,
      IonList,
      IonItem,
      IonText,
      IonButtons,
      IonBackButton,
      //IonButton,
      //IonIcon
    },
    computed: {
        loadedList() {
            const wl = this.$store.getters.region_list;
            return wl;
        }
    },
    mounted() {
      if (this.$store.getters.region_id == -1) {
        this.$router.replace('/home');
      }
    },
    methods: {
      selectWindgram(windgram) {
        this.$store.commit('saveWindgram',windgram);
        this.$router.push('/windgram');        
      }
    }
}
</script>