import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue';
import RegionPage from '../views/regionPage.vue';
import WindgramPage from '../views/windgramPage.vue';
import LoginPage from '../views/loginPage.vue';
import store from '../store';
import disclamerPage from '@/views/disclamerPage.vue';
import newsPage from '@/views/newsPage.vue';

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage,
    beforeEnter: (to, from, next) => {
      store.commit('loadKey');
      store.commit('loadVersion');
      setTimeout(() => {
        const theKey = store.getters.key;
        if (theKey == '' || theKey == null) {
          next("/login");
        } else {
          next();
        }
      }, 300); 
    }
  },
  {
    path: '/region',
    component: RegionPage,
    //component: () => import('../views/regionPage.vue')
  },
  {
    path: '/windgram',
    component: WindgramPage,
    //component: () => import('../views/windgramPage.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    beforeEnter: (to, from, next) => {
      store.commit('loadKey');
      store.commit('loadVersion');
      setTimeout(() => {
        const theKey = store.getters.key;
        if (theKey == '' || theKey == null) {
          next();
        } else {
          next("/home");
        }
      }, 300); 
    }
  },
  {
    path: '/disclaimer',
    name: 'Disclamer',
    component: disclamerPage,
  },
  {
    path: '/news',
    name: 'News',
    component: newsPage
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


export default router
