<template>
 <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
         <ion-title >Disclaimer</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen class="ion-padding">
        <div style="text-align: justify;text-justify:inter-word;font-wight:bold">
            <ion-text >
            <h4>
Queste previsioni meteo sono fornite
a puro titolo indicativo e soggette ad interpretazione. Gli autori non saranno in
alcun modo responsabili delle eventuali conseguenze derivanti da errori nelle
previsioni o dall'interpretazione errata delle stesse durante la pratica di
qualsiasi attività outdoor, incluso il VDS.
            </h4>
            <br/>
            <h4>
The Authors make any representations or warranties, 
either expressed or implied, as to the accuracy of the information held herein or its fitness
for any purpose whatsoever. In no event will either party be held liable for any direct, indirect,
special incidental or consequential damages arising out of the use of the information held herein.
            </h4>
            </ion-text>
        </div>
    </ion-content>
    <ion-footer class="ion-no-border" >
        <ion-button expand="block" @click='closeDisclamer'>Accetto</ion-button>
        <ion-button expand="block" @click='exit'>Esci</ion-button>
    </ion-footer>
  </ion-page>
</template>

<script>

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonFooter, IonText} from '@ionic/vue';
import { App as CapacitorApp } from '@capacitor/app';

export default {
     components: {
      IonContent,
      IonHeader,
      IonPage,
      IonTitle,
      IonToolbar,
      IonButton,   
      IonFooter,
      IonText
    },
    data() {
        return {
        }
    },
    methods: {
        closeDisclamer() {
            this.$store.commit('doneDisclamer');
            this.$router.replace('/home'); 
        },
        exit() {
            this.$store.commit('deleteKey');
            CapacitorApp.exitApp();
        }
    },
    //mounted() {
    //    setTimeout(() => {
    //        this.$router.replace('/home');
    //    }, 100);
    //}
}

</script>
