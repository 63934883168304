<template>
  <img :src='mapURL' style='padding-bottom: 20px; padding-left: 20px;padding-right: 20px;padding-top: 10px'>
</template>

<script>
import { onMounted} from "vue";

export default {
  name: "GMap",
  props: {
    selectedWindgramId: String,
    zoom: Number,
    mapType: String,
    lat: Number,
    lng: Number,
  },
  computed: {
    mapURL(props) {
         return "https://www.cumulus.it/php/cachedStaticMap.php?wgID="+props.selectedWindgramId+"&zoom="+props.zoom+"&w=500&h=300&lat="+props.lat+"&lon="+props.lng
        //return "https://maps.googleapis.com/maps/api/staticmap?center="+props.lat+"+"+props.lng+"&zoom="+props.zoom+"&scale=false&size=500x300&maptype=terrain&key=AIzaSyDrm-7Es65i_yo7PjIEG_Ym3FrEJV1xsRw&format=png&markers=size:mid%7Ccolor:0xff0000%7Clabel:%7C"+props.lat+"+"+props.lng
    }
  },

  setup() {

    onMounted(() => {
    });
    return {
    };
  },
};
</script>

<style lang="css" scoped>
.map {
  width: 90%;
  height: 40%;
  margin: auto;
  padding: 10px;
}
</style>
