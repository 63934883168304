<template>
 <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title >Attivazione necessaria</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen class="ion-padding">
        <ion-text>
            <p align="justify">
            Per attivare la App occorre inserire la chiave di attivazione che si genera utilizzando il pulsante verde in alto a destra (quello con l'icona di robot) nella pagina meteo dopo aver
            fatto login con la propria username/password al sito
            <a href="https://www.fivl.it" target="_blank">www.fivl.it</a>
            oppure 
            <a href="https://www.cumulus.it" target="_blank">www.cumulus.it</a>
            oppure 
            <a href="https://www.ilpulcino.org" target="_blank">www.ilpulcino.org</a> (a seconda 
            di dove è stato caricato il credito personale).
            </p>
            <p align="justify">
            Ogni chiave di attivazione può essere utilizzata una sola volta, ma non c'è limite sul numero di chiavi
            che è possibile generare. Ad esempio, occorre generare dal sito una nuova chiave di attivazione se si deve reinstallare l'App.
            </p>
            <p align="justify">
            Una volta attivata, la App utilizza, per la visualizzazione dei windgram, il credito dell'utente che ha generato la chiave.
            </p>
        </ion-text>
        <form class="ion-padding" @submit.prevent="submitForm">
            <ion-list>
                <ion-item>
                    <ion-label position="stacked">Inserire la chiave di attivazione</ion-label>
                    <ion-input mode="ios" placeholder="XXXX-XXXX-XXXX-XXXX" type="text" required v-model="enteredKey" />
                </ion-item>
            </ion-list>
            <ion-button type="submit" expand="block">Attiva</ion-button>
        </form> 
    </ion-content>
  </ion-page>
</template>

<script>

import { IonText, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonItem, IonButton, IonLabel, IonInput, alertController } from '@ionic/vue';

export default {
     components: {
      IonContent,
      IonHeader,
      IonPage,
      IonTitle,
      IonToolbar,
      IonList,
      IonItem,
      IonButton,
      IonLabel,
      IonInput,
      IonText
    },
    data() {
        return {
            enteredKey: ''
        }
    },
    mounted() {
      if (this.$store.getters.region_id == -1) {
        this.$router.replace('/home');
      }
    },
    methods: {
        async submitForm() {
            const res = await fetch("https://www.cumulus.it/php/rasp_mobile.php?key="+this.enteredKey+"&req=reg");
            const response = await res.text();
            if (response == 'OK') {
                this.$store.commit('saveKey',this.enteredKey);
                this.$router.replace('/disclaimer');
            } else {
                //alert(response);
                this.openAlert();
            }
            
        },
        async openAlert() {
            const alert = await alertController.create({
                header: "Attenzione!",
                subHeader: "La chiave inserita non è valida.",
                message: "Inserire una chiave generata facendo click sul pulsante 'android' in alto a destra nella pagina meteo. Ciascuna chiave può essere utilizzata per una sola attivazione.",
                buttons: ["OK"],
            });
            await alert.present();
        }
    },
    setup() {
        
    },
}

</script>
