<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="openProfile">
            <ion-icon slot="icon-only" :icon="person"/>
          </ion-button>
          <ion-button @click="openInfo">
            <ion-icon slot="icon-only" :icon="informationCircleOutline"/>
          </ion-button>
        </ion-buttons>
        <ion-title>Seleziona la Regione</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item
          v-for="item in loadedWindgrams"
          :key="item.region_id" 
          @click="selectRegion(item.region_id)"
        >
          <div>
          <ion-icon  v-if="item.region_id == '0'" slot="start" :icon="heart"/>
          {{item.region_name}}
          </div>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonList, IonItem, IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonIcon, IonButtons, IonButton, alertController, } from '@ionic/vue';
import { defineComponent } from 'vue';
import {person, heart, informationCircleOutline} from 'ionicons/icons';
import { useRouter } from 'vue-router';
import { useBackButton, useIonRouter } from '@ionic/vue';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from "@capacitor/core";
//import { AppVersion } from '@ionic-native/app-version' ;


export default defineComponent({
  name: 'HomePage',
  components: {
    IonList,
    IonItem,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonIcon,
    IonButtons,
    IonButton,
  },
  setup() {
    const router = useRouter();
    const ionRouter = useIonRouter();  
    useBackButton(-1, () => {
      if (!ionRouter.canGoBack()) {
        //CapacitorApp.exitApp();
        const answer = window.confirm(': Sei sicuro di voler uscire?');
        if (answer) CapacitorApp.exitApp();
      }
    });
    return {
      router
    }
  },
  data() {
    return {
       person,
       heart,
       informationCircleOutline,
    }
  },
  methods: {
    async checkVersion() {
      const res = await fetch('https://www.cumulus.it/php/ajax.new.php?type=v-version')
      const versionInfo = await res.json();
      const currentVersion = this.$store.getters.version;
      if ( currentVersion === null || versionInfo.version != currentVersion.version ) {
              const alert = await alertController.create({
                header: "Disponibile nuova versione.",
                subHeader: "",
                message: "E' necessario riavviare l'App.",
                buttons: [
                  {
                    text: 'Riavvia',
                    id: 'reload-button',
                    handler: () => {
                      this.$store.commit('saveVersion',versionInfo);
                      window.location.reload(true);
                    }
                  },
                  'Cancel'
                ],
              });
              this.router.replace('/home');
              await alert.present();
      }
    },
    selectRegion(id) {
      this.$store.commit('saveRegion',id);
      //console.log(this.$store.getters.region_id);
      //console.log(localStorage.getItem('key'));
      this.$router.push('/region');
    },
    async openProfile() {
      const res = await fetch("https://www.cumulus.it/php/paytest-mobile.php?lang=it&key="+this.$store.getters.key);
      const profile = await res.text();
      const alert = await alertController.create({
                header: "Dati dell'account in uso",
                subHeader: "",
                message: profile,
                buttons: ["OK"],
      });
      await alert.present();
    },
    async openInfo() {
        const ver = process.env.VUE_APP_VERSION; 
        const alert = await alertController.create({
          header: "Windgram Viewer",
                subHeader: "By Andrea Barcellona",
                message: "Version " + ver,
                buttons: ["OK"],
        });
        await alert.present();
        //window.location.reload(true);
    },
    async exitConfirm() {
      const alert = await alertController.create({
                header: "Confermi di voler uscire?",
                subHeader: "",
                message: '',
                buttons: [
                  {
                    text: 'OK',
                    id: 'confirm-button',
                    handler: () => {
                      CapacitorApp.exitApp()
                    }
                  },
                  'Cancel'
                ],
      });
      await alert.present();
      CapacitorApp.exitApp();
    },
    async openNews() {
      try {
        const res = await fetch('https://www.cumulus.it/avvisi/mobile-notice.php');
        const newsText = await res.text();
        if (newsText.trim() != 'none') {
          const alert = await alertController.create({
                header: "News",
                subHeader: "",
                message: newsText,
                buttons: ["OK"],
          });
          await alert.present();
        }
      } catch {
        //alert('network error in news')
      }
    },
  },
  computed: {
    loadedWindgrams() {
      return this.$store.getters.windgrams;
    },
  },
  async mounted() {
    if ( !Capacitor.isNativePlatform() ) {
      setInterval(this.checkVersion, 10000);
    }
    await this.$store.dispatch('loadPreferred');
    await this.$store.dispatch('fetchWindgrams');
    this.openNews()
    //console.log(process.env.VUE_APP_VERSION)

  }
});
</script>

<style scoped>

#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

</style>
