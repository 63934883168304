<template>
  <ion-page>
    <ion-header v-if="windgram.windgram_id != 0" :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click="prefer()">
            <ion-icon v-if="!isPreferred" slot="icon-only" :icon="heartOutline"/>
            <ion-icon v-else slot="icon-only" :icon="heart"/>
          </ion-button>
        </ion-buttons>
        <ion-title>{{windgram.windgram_name}}</ion-title>
      </ion-toolbar>
     </ion-header>
    <ion-content v-if="windgram.windgram_id != 0">
        <ion-segment :value="day" @ionChange="dayChanged($event)" mode='ios'>
          <ion-segment-button v-for="id in 5" :key="id" :value="id - 1">
            <ion-label><h2>{{thisDays[id-1]}}</h2></ion-label>
          </ion-segment-button>
        </ion-segment>
      <swiper :modules="modules" :zoom="true">
        <swiper-slide >
          <div class="swiper-zoom-container">
            <img :src='windgramURL'/>
          </div>
        </swiper-slide >
      </swiper>
      <swiper :modules="modules" :zoom="true">
        <swiper-slide >
          <div class="swiper-zoom-container">
            <g-map-static 
              :selectedWindgramId = "windgram.windgram_id"
              :lat = "lat"
              :lng = "lng"
              :zoom= "10"
            >
            </g-map-static>
          </div>
        </swiper-slide>
      </swiper>


      <!--
      <div class="ion-text-center">
        <h4>Usa la mappa per aprire un altro Windgram nelle vicinanze</h4>
      </div>
      <g-map 
        :lat = "lat"
        :lng = "lng"
        :zoom= "10"
        :selectedWindgramId = "windgram.windgram_id"
        :allWindgrams = "allWindgrams"
        @newWindgramId="windgramChanged"
      >
      </g-map>
      -->
    </ion-content>
  </ion-page>
</template>

<script>

import { toastController, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonButton, IonBackButton, IonSegment, IonLabel, IonIcon, IonSegmentButton, loadingController} from '@ionic/vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
//import { onIonViewWillLeave } from "@ionic/vue";
import 'swiper/css';
import 'swiper/css/zoom';
import '@ionic/vue/css/ionic-swiper.css';
import {heart, heartOutline} from 'ionicons/icons';
import { Zoom } from 'swiper';
//import GMap from "@/components/GMap.vue";
import GMapStatic from "@/components/GMapStatic.vue";
import { onMounted } from "vue";

export default {
    components: {
      IonContent,
      IonHeader,
      IonPage,
      IonTitle,
      IonToolbar,
      IonButtons,
      IonButton,
      IonBackButton,
      IonSegment,
      IonLabel,
      IonSegmentButton,
      Swiper,
      SwiperSlide,
      IonIcon,
      //GMap
      GMapStatic,
    },
    //ionViewDidLeave() {
    //  this.$store.commit('deleteMap');
    //},
    data() {
        return {
            windgram: this.$store.getters.windgram,
            regionId: this.$store.getters.region_id,
            day: this.$store.getters.day,
            
            spinner: false,
            loading: null,
            showingLoading: false,
            lat: parseFloat(this.$store.getters.windgram.coord.lat),
            lng: parseFloat(this.$store.getters.windgram.coord.lng),
            isPreferred: this.$store.getters.isPreferred,
            allWindgrams: this.$store.getters.allWindgrams
        }
    },
    computed: {
      windgramURL() {
          return 'https://www.cumulus.it/rasp/publicwg.php?type=WINDGRAMS&key='+this.$store.getters.key+'&secret='+this.windgram.windgram_id+'&day='+this.day
      },
    },
    methods: {
      windgramChanged(wg) {
        this.windgram = this.allWindgrams.filter( el => el.windgram_id == wg )[0];
        this.lat = parseFloat(this.windgram.coord.lat);
        this.lng = parseFloat(this.windgram.coord.lng);
        this.selectedWindgramId = wg;
        this.$store.commit('saveWindgram',this.windgram);
        this.isPreferred = this.$store.getters.isPreferred;
      },
      dayChanged(ev) {
        this.day = ev.detail.value;
        this.$store.commit('saveDay',this.day);
      },
      async startLoader() {
        // <ion-img :src='windgramURL' @ionImgWillLoad='startLoader' @ionImgDidLoad='stopLoader' />
        this.loading = await loadingController
          .create({
           //cssClass: 'my-custom-class',
          message: 'Loading Windgram..',
          //duration: this.timeout,
        });
        this.showingLoading = true;
        this.loading.present();
        //setTimeout(function(){ // visualizza il loader solo se il caricamento impiega più di 1500ms
        //  if (this.showingLoading) this.loading.present();
        //}, 1500);
        
      },
      stopLoader() {
        if (this.showingLoading) {
          this.showingLoading = false;
          this.loading.dismiss();
        }
      },    
      async prefer() {
        let message = ""; 
        //this.isPreferred = this.$store.getters.isPreferred;
        if (!this.isPreferred) {
          this.$store.commit('addPreferred');
          message = 'Aggiunto ai preferiti';
          this.isPreferred = true;
        } else {
          this.$store.commit('removePreferred');
          message = "Rimosso dai preferiti";
          this.isPreferred = false;
        }
        const toast = await toastController.create({
                duration: 2000,
                color: 'dark',
                message: message,
                cssClass: 'toastStyle',
                mode: 'ios'
                //buttons: ["OK"],
        });
        //setTimeout(() => {alert.dismiss()},1200);
        await toast.present();
      },  
    },
    mounted() {
      if (this.windgram.windgram_id == 0) {
        this.$router.replace('/home');
      }
    },
    setup() {
      const weekDays = ['Dom','Lun','Mar','Mer','Gio','Ven','Sab'];
      const thisDays = [];
      //onIonViewWillLeave(() => {
      //  console.log('WINDGRAM page will leave');
      //});
      onMounted(() => {
        const d = new Date();
        const today = d.getDay();
        for (let index = 0; index < 5; index++) {
          thisDays.push(weekDays[( today + index) % 7])
        }
      });
      return {
        modules: [Zoom],
        thisDays,
        heart,
        heartOutline
      };
    },

}
</script>

.toastStyle {
  --border-radius: 30px;

}

<style lang="CSS" scoped>


</style>