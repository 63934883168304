<template>
 <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
         <ion-title >News</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen class="ion-padding">
        <p>{{newsText}}</p>
    </ion-content>
    <ion-footer class="ion-no-border" >
        <ion-button expand="block" @click='closeNews'>OK</ion-button>
    </ion-footer>
  </ion-page>
</template>

<script>

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonFooter} from '@ionic/vue';

export default {
     components: {
      IonContent,
      IonHeader,
      IonPage,
      IonTitle,
      IonToolbar,
      IonButton,   
      IonFooter
    },
    data() {
        return {
            newsText: ''
        }
    },
    methods: {
        closeNews() {
            this.$store.commit('doneNews');
            this.$router.replace('/home'); 
        },
    },
}

</script>
